<template>
	<div class="booking-card">
		<div class="booking-card__head">
			<div @click="showDescModalUser" class="userpic">
				<tk-image
					width="70"
					height="70"
					:alt="userName"
					:src="booking.user.avatar ? booking.user.avatar : '/images/default-avatar.svg'"
				/>
			</div>

			<div class="username-wrapper">
				<p class="username">{{ userName }}</p>
				<router-link
					to="/profile/chat"
					@click.prevent="handlerContactWithGuest(booking.user_id)"
					class="chat-link"
					v-if="
						contentByStatus.key === 'new' ||
						contentByStatus.key === 'edited' ||
						contentByStatus.key === 'completed' ||
						contentByStatus.key === 'accepted' ||
						contentByStatus.key === 'inprogress'
					"
				>
					Написать
				</router-link>
			</div>
		</div>

		<div class="booking-card__status" :class="contentByStatus.key">
			{{ contentByStatus.title }}
		</div>

		<h3 class="booking-card__place-name">
			{{ booking.title }}
		</h3>

		<p class="booking-card__date">
			Дата:
			<span>
				{{ getDate(booking.date_from) }} – {{ getDate(booking.date_to) }}
			</span>
		</p>

		<p class="booking-card__payment">
			Оплачено: <span>{{ booking.price }} ₽</span>
		</p>

		<p class="booking-card__guests">
			Гостей:
			<span>
				{{ getGuestsTextFormat(booking.guests) }},
				{{ getChildrenTextFormat(booking.children) }}
			</span>
		</p>

		<!-- <p class="booking-card__reason" v-if="contentByStatus.key === 'rejected'">
			Указанная причина:<span>{{ booking.rejectReason }}</span>
		</p> -->

		<button
			class="btn booking-card__accept-btn"
			v-if="contentByStatus.key === 'new' || contentByStatus.key === 'edited'"
			@click="showBookingAcceptDialogModal"
		>
			Принять
		</button>

		<router-link
			:to="`booking/reject/${booking.id}`"
			class="btn booking-card__reject-btn"
			v-if="contentByStatus.key === 'new' || contentByStatus.key === 'edited'"
		>
			Отклонить
		</router-link>

		<!-- <router-link
			to="/chat"
			class="btn booking-card__accept-btn"
			v-if="isShowContactGuestLink"
		>
			Связаться с гостем
		</router-link> -->

		<router-link
			:to="`/profile/reviews/master/${booking.id}`"
			class="btn booking-card__accept-btn"
			v-if="isShowSendReviewLink"
		>
			<tk-svg type="message-send" /> Оставить отзыв
		</router-link>

		<a
			class="btn booking-card__accept-btn"
			v-if="booking.status === 6 || booking.status === 7"
			@click="showBookingReasonModal"
		>
			<tk-svg type="info" /> Узнать причину
		</a>
	</div>
</template>

<script>
import { getDateInFormat } from '@/utils/dateTime'
import { arrToObj } from '@/utils/normalizeData'
import { getUserName } from '@/utils/helpers'
import declension from '@/utils/declension'
import modals from "@/constants/modals";

const statuses = {
	1: {
		title: 'Новое бронирование',
		key: 'new'
	},
	2: {
		title: 'Бронирование подтверждено',
		key: 'accepted'
	},
	3: {
		title: 'Активное бронирование',
		key: 'inprogress'
	},
	4: {
		title: 'Бронирование изменено',
		key: 'edited'
	},
	5: {
		title: 'Бронирование завершено',
		key: 'completed'
	},
	6: {
		title: 'Бронирование отклонено',
		key: 'rejected'
	},
	7: {
		title: 'Бронирование отменено',
		key: 'canceled'
	}
}

// Refactoring

export default {
  inject: ['chat'],
	props: {
		booking: {
			type: Object,
			default: () => ({})
		}
	},
	computed: {
		userName() {
			return getUserName(this.booking.user)
		},
		contentByStatus() {
			return this.booking.status ? statuses[this.booking.status] : {}
		},
		isShowGetReasonModal() {
			return this.contentByStatus.key === 'canceled'
		},
		isShowSendReviewLink() {
			return this.contentByStatus.key === 'completed' && !this.booking.review_date
		},
		isShowContactGuestLink() {
			return (
				this.contentByStatus.key === 'accepted' ||
				this.contentByStatus.key === 'inprogress' ||
				this.contentByStatus.key === 'rejected' ||
				this.contentByStatus.key === 'completed'
			)
		},
		rejectReasonTypes() {
			return arrToObj(this.$store.getters.guestRejectTypes, 'code')
		}
	},
	methods: {
    showDescModalUser() {
      this.$store.commit('showModal', {
        name: modals.DESCRIPTION_USER,
        props: {
          id: this.booking.user_id
        }
      })
    },
    handlerContactWithGuest(id) {
      this.chat.getOrCreateConversationWithUser(id)

      this.$router.push('/profile/chat')
    },
		getGuestsTextFormat(payload) {
			return `${payload} ${declension(
				['взрослый', 'взрослых', 'взрослые'],
				+payload
			)}`
		},
		getChildrenTextFormat(payload) {
			return `${payload} ${declension(['ребёнок', 'дети', 'детей'], +payload)}`
		},
		async showBookingAcceptDialogModal() {
			let user = await this.$api.users.get({ ids: [this.booking.user_id] })

			if (user?.status) {
				user = user.response.users[0]
			}

			this.$store.commit('showModal', {
				name: 'BookingRejectConfirmByOwner',
				props: {
					isConfirm: true,
					values: { ...this.booking, ...user },
					callback: async (isConfirm) => {
						if (isConfirm) {
							const response = await this.$api.reserve.toggleBookingOwner({
								reserve_id: this.booking.id,
								isAllow: true
							})

							if (response?.status) {
								this.$store.commit('showModal', {
									name: 'CommonInfo',
									props: {
										text: `Заявка на бронирование успешно подтверждена! </br> Ожидайте гостя в указанную дату на заселение.`
									}
								})

								this.$router.back()
                this.$emit('update')
                // TODO: refactoring
							} else {
								this.$store.commit('showNotification', {
									type: 'success',
									text: 'Ошибка принятия заявки. Обратитесь к администратору'
								})
							}
						}
					}
				}
			})
		},
		async showBookingReasonModal() {
			const response = await this.$api.reserve.getReasonRejectOwner({
				reserve_id: this.booking.id
			})

			if (response?.status) {
				const data = response.response.message
					? response.response.message
					: this.rejectReasonTypes[response.response.reason_code[0]]
					? this.rejectReasonTypes[response.response.reason_code[0]].title
					: 'Гость не указал причину отказа'

				this.$store.commit('showModal', {
					name: 'CommonInfo',
					props: {
						text: `<b>Указанная причина</b>: ${data}`
					}
				})
			}
		},
		getDate(payload) {
			return getDateInFormat(payload)
		}
	}
}
</script>

<style lang="sass">
.booking-card
  position: relative
  width: 100%
  max-width: 310px
  padding: 20px
  background: #FFFFFF
  border: 1px solid #CBCBCB
  box-sizing: border-box
  border-radius: 12px
  transition: all 0.1s linear
  &:hover
    border: 1px solid #1AC386
    box-shadow: 0 5px 15px rgba(95, 83, 80, 0.15)
  &__head
    display: flex
    align-items: center
    margin-bottom: 20px
    .userpic
      cursor: pointer
      width: 70px
      height: 70px
      margin-right: 15px
      img
        width: 100%
        height: 100%
        border-radius: 50%
        object-fit: cover
    .username-wrapper
      width: 60%
    .username
      font-size: 16px
      line-height: 14px
      font-weight: 600
      margin-bottom: 7px
    .chat-link
      position: relative
      font-weight: 500
      font-size: 14px
      line-height: 14px
      color: #CBCBCB
      padding-right: 30px
      &:hover
        color: #1ac386
        &:after
          content: url('~@/assets/images/svg/chats-green.svg')
      &:after
        content: url('~@/assets/images/svg/chats.svg')
        position: absolute
        right: 0
  &__status
    display: inline-block
    font-size: 14px
    line-height: 14px
    color: #fff
    padding: 6px 11px
    margin-bottom: 10px
    border-radius: 13px
    &.new
      background: #3B5998
    &.edited
      background: #FFDA2D
    &.accepted
      background: #1AC386
    &.inprogress
      background: #FF922F
    &.rejected
      background: #E93030
    &.canceled
      background: #574343
    &.completed
      background: #CBCBCB
  &__place-name
    font-weight: 600
    font-size: 16px
    line-height: 18px
    margin-bottom: 15px
    height: 36px
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical
    overflow: hidden
  &__date,
  &__payment,
  &__guests,
  &__reason
    font-size: 12px
    line-height: 14px
    margin-bottom: 5px
    span
      font-weight: bold
  &__guests
    margin-bottom: 11px
  &__payment
    margin-bottom: 20px
  &__reason
    font-weight: bold
    max-height: 42px
    overflow-x: auto
    span
      font-weight: normal
  &__reject-btn,
  &__chat-btn,
  &__accept-btn,
  &__reason-btn,
  &__feedback-btn
    height: 40px
    font-size: 15px
    line-height: 15px
    padding: 12px 12px 13px
    text-align: center
  &__accept-btn
    display: flex
    align-items: center
    justify-content: center
    margin-top: 10px
    max-width: 270px
    width: 100%
    height: 40px
    font-weight: 600
    font-size: 15px
    line-height: 100%
    color: #FFFFFF
    background: #1AC386
    border-radius: 13px

    .svg-icon
      width: 16px
      height: 16px
      margin-right: 20px
      margin-left: -25px
  &__reject-btn
    display: block
    margin-top: 10px
    width: 100%
    height: 40px
    background: #fff
    border: 1px solid #E93030
    color: #E93030
    border-radius: 13px
    box-sizing: border-box
    &:hover
      background: #E93030
      color: #fff
</style>
