<template>
	<section class="booking-section">
		<div class="booking">
			<div class="booking__title-wrapper">
				<div class="booking__title-wrapper--left">
					<h2 class="section-title">Заявки на бронирование</h2>
					<!-- <el-select
							class="select"
							v-model="placeValue"
							placeholder="Всё жильё"
							:popper-append-to-body="false"
						>
							<el-option
								v-for="item in placeOptions"
								:key="item.placeId"
								:label="item.label"
								:value="item.placeId"
							>
							</el-option>
						</el-select> -->
				</div>

				<div class="booking__title-wrapper--right">
					<template v-for="item in config" :key="item.key">
						<tk-button
							type="button"
							@click="() => { current = item.key; page = 1 }"
							:class="{ active: current === item.key }"
							kind="title-btn"
						>
							{{ item.toggle }}
						</tk-button>
					</template>
				</div>
			</div>

			<ul class="booking-cards">
				<template v-if="values.items?.length">
					<template v-for="itm in values.items" :key="itm.id">
						<li>
							<BookingCard @update="get" :booking="itm" />
						</li>

					</template>
				</template>
				<div v-else>{{ unit.empty }}</div>
			</ul>

			<tk-pagination
				v-if="values.meta"
        class="booking__pagination"
				:content="values.meta"
				:offsetPage="page"
				@next="changePage"
			/>
		</div>
	</section>
</template>

<script>
import BookingCard from '@/components/cards/Booking'

const _config = [
	{
		key: 'future',
		toggle: 'Предстоящие',
		empty: 'У вас нет заявок на бронирование.'
	},
	{
		key: 'active',
		toggle: 'Активные',
		empty: 'На данный момент, у вас никто не гостит.'
	},
	{
		key: 'past',
		toggle: 'Завершённые',
		empty: 'У вас ещё никто не гостил.'
	}
]

export default {
	name: 'Booking',
	components: { BookingCard },
	watch: {
		current: async function () {
			this.$router.push({ query: { current: this.current } })

			await this.get()
		},
		page: async function () {
			await this.get()
		}
	},
	data() {
		return {
			current: this.$route.query?.current || 'active',
			page: 1,
			values: {},
      getAbortController: new AbortController(),
		}
	},
	computed: {
		config() {
			return _config
		},
		unit() {
			return this.config.find((itm) => itm.key === this.current)
		}
	},
	methods: {
		async get() {
      this.getAbortController?.abort()
      this.getAbortController = new AbortController()
			const response = await this.$api.reserve.getReserves({
				type: this.current,
				page: this.page
			}, { controller: this.getAbortController })

			if (response && response.status) {
				this.values = response.response
			}
		},
		changePage(payload) {
			this.page = payload
		}
	},
	async beforeMount() {
		await this.get()
	},
  beforeUnmount() {
    this.getAbortController.abort()
  }
}
</script>


<style lang="sass">
.booking
  &__pagination
    margin-top: 50px
</style>
